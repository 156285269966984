 
 
 body
 {
     background: #00ff00;
     font-family : "Comic Sans MS" , "Brush Script MT" , cursive;
 }
 
 a:link, a:visited
 {
     color: #034af3;
 }
 
 a:hover
 {
     color: #ff00ff;
 }
 
 a:active
 {
     color: #034af3;
 } 
  
 h1
 {
     font-size: 2.2em;
     font-weight: 900;
 }
 
 h2
 {
     font-size: 1.9em;
     font-weight: 600;
 }
 
 h3
 {
     font-size: 1.6em;
 }
 
 h4
 {
     font-size: 1.2em;
 }
 
 h5, h6
 {
     font-size: 1em;
 }

.header
{
  text-align: center;
}

 .header h1
 {
     font-weight: 700;
     color: #009bff;
     font-size: 2em;
 }
 
 .footer
 {
     color: #4e5766;
     text-align: center;
 }
 
 /* Custom by Kris
 --------------------------------*/
   
 .tag-callout {
   position: absolute;
   right: 0px;
   top: 0px;
   width: auto;
   height: auto;
   overflow: show;
   z-index: -1000;
 }
 
.align-center {
  text-align: center;
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.thick-border {
    border: 6px solid;
}
